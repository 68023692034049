import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import { App } from './app/app';
import { CustomRouter } from './app/components/common/custom-router';
import { customHistory } from './app/utils/history.utils';
import { WalletProvider } from './app/contexts/wallet-context';
import { LandingPage } from './app/routes/landing-page';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<LandingPage />
	</React.StrictMode>
);
