import chickens from '../../assets/images/chickens.gif';
import chickensMobile from '../../assets/images/chickens-mobile.gif'
import sparkle1 from '../../assets/images/sparkle-1.gif';
import sparkle2 from '../../assets/images/sparkle-2.gif';
import sparkle3 from '../../assets/images/sparkle-3.gif';
import sparkle4 from '../../assets/images/sparkle-4.gif';
import sparkle5 from '../../assets/images/sparkle-5.gif';
import sparkle6 from '../../assets/images/sparkle-6.gif';
import './mint.css';
import { useState } from 'react';

export const LandingPage = () => {
	const [wallet, setWallet] = useState('');
	const [checking, setChecking] = useState(false);

	const handleWalletInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWallet(event.target.value);
	}

	const getColor = () => {
		return 'bg-gradient-to-b from-[#f1ea0b] to-80% to-white';
	};

	const checkWallet = async () => {
		setChecking(true);

		const encoder = new TextEncoder();

		const encodedWallet = encoder.encode(wallet.trim());

        // Hash the data using SHA-256
        const walletHashBuffer = await crypto.subtle.digest('SHA-256', encodedWallet);
        const walletHashArray = Array.from(new Uint8Array(walletHashBuffer));
        const walletHashHex = walletHashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		const key = walletHashHex.substring(0, 2);

		const response = await fetch(`/data/${key}.json`);

		if (response.ok) {
			let hashes: string[] = await response.json();
			if (hashes.includes(walletHashHex)) {
				alert ('You are on the whitelist!');
			} else {
				alert('Wallet not found :(');
			}
		} else {
			alert('Wallet not found :(');
		}

		setChecking(false);
	  };

	return (
		<div className={`flex flex-col ${getColor()} min-h-screen md:h-screen font-[Circular]`}>
			<div className="flex mt-[18px] mr-[24px]">

				{/* <div className="font-[Minusio] -rotate-90 text-[10px] font-medium ml-[30px] lg:ml-[50px] -mt-[6px] -mr-[19px]">BTC</div> */}
				{/* <div className="font-[Minusio] text-[40px] font-medium leading-[35px]">Chickens</div> */}
				<div className="font-[Minusio] text-[40px] ml-[24px] font-medium leading-[35px]">BTC Chickens</div>
				<div className="grow" />

				<a
					href="https://x.com/BTCchickens"
					className="flex items-center justify-center bg-black rounded-[10px] w-[50px] h-[50px] text-white font-[Minusio] font-medium text-[25px] leading-[22px] ml-[24px]"
				>
					<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0,0,256,256">
						<g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(10.66667,10.66667)"><path d="M2.36719,3l7.0957,10.14063l-6.72266,7.85938h2.64063l5.26367,-6.16992l4.31641,6.16992h6.91016l-7.42187,-10.625l6.29102,-7.375h-2.59961l-4.86914,5.6875l-3.97266,-5.6875zM6.20703,5h2.04883l9.77734,14h-2.03125z"></path></g></g>
					</svg>
				</a>
				<a
					href="https://discord.gg/BbC4duhsvj"
					className="hidden lg:flex items-center justify-center bg-black rounded-[10px] w-[50px] h-[50px] text-white font-[Minusio] font-medium text-[25px] leading-[22px] ml-[24px]"
				>
					<svg x="0px" y="0px" width="30" height="30" viewBox="0 0 512 512" fill="#000000" aria-label="Discord" role="img" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_iconCarrier"><path d="m386 137c-24-11-49.5-19-76.3-23.7c-.5 0-1 0-1.2.6c-3.3 5.9-7 13.5-9.5 19.5c-29-4.3-57.5-4.3-85.7 0c-2.6-6.2-6.3-13.7-10-19.5c-.3-.4-.7-.7-1.2-.6c-23 4.6-52.4 13-76 23.7c-.2 0-.4.2-.5.4c-49 73-62 143-55 213c0 .3.2.7.5 1c32 23.6 63 38 93.6 47.3c.5 0 1 0 1.3-.4c7.2-9.8 13.6-20.2 19.2-31.2c.3-.6 0-1.4-.7-1.6c-10-4-20-8.6-29.3-14c-.7-.4-.8-1.5 0-2c2-1.5 4-3 5.8-4.5c.3-.3.8-.3 1.2-.2c61.4 28 128 28 188 0c.4-.2.9-.1 1.2.1c1.9 1.6 3.8 3.1 5.8 4.6c.7.5.6 1.6 0 2c-9.3 5.5-19 10-29.3 14c-.7.3-1 1-.6 1.7c5.6 11 12.1 21.3 19 31c.3.4.8.6 1.3.4c30.6-9.5 61.7-23.8 93.8-47.3c.3-.2.5-.5.5-1c7.8-80.9-13.1-151-55.4-213c0-.2-.3-.4-.5-.4Zm-192 171c-19 0-34-17-34-38c0-21 15-38 34-38c19 0 34 17 34 38c0 21-15 38-34 38zm125 0c-19 0-34-17-34-38c0-21 15-38 34-38c19 0 34 17 34 38c0 21-15 38-34 38z" fill="#ffffff"></path> </g></svg>
				</a>
			</div>

			<div className="z-10 flex w-full flex-col items-center absolute left-[50%] top-[45%] -translate-x-[50%] -translate-y-[50%] px-[20px] sm:p-0">
				<div className="text-center font-[Minusio] text-[125px] leading-[75px] sm:text-[200px] sm:leading-[150px] mb-[25px] sm:mb-[40px] mt-[11px]">
					Chicken It Up
				</div>
				<iframe width="560" height="315" src="https://www.youtube.com/embed/i_Sig5BE48Q?si=w96GwHxmbJJWFHH3" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe>
				<div className="flex flex-row mt-[25px] sm:mt-[40px]">
					<input
						className="font-[Minusio] text-[30px] p-2 pt-1 mr-[25px] border-2 border-black"
						type="text" placeholder="Wallet address"
						value={wallet}
						onChange={handleWalletInputChange}
					></input>
					<button
						className="font-[Minusio] text-[30px]  p-2 pt-1 mr-[25px] bg-black text-white"
						onClick={checkWallet}
						disabled={checking}
					>{checking ? 'Checking...' : 'Check Wallet!'}</button>
				</div>
			</div>

			<img className="hidden md:flex absolute left-[50%] -translate-x-[50%] cheetah-img w-[2318px] max-w-none" src={chickens} alt="" />
			<img className={`absolute bottom-0 md:hidden`} src={chickensMobile} alt="" />
			<img className="absolute top-[25%] left-[9%] w-[20px] h-[20px]" src={sparkle1} alt="" />
			<img className="absolute top-[65%] left-[34%] w-[20px] h-[20px]" src={sparkle2} alt="" />
			<img className="absolute top-[18%] left-[44%] w-[35px] h-[35px]" src={sparkle3} alt="" />
			<img className="absolute top-[67%] left-[53%] w-[25px] h-[25px]" src={sparkle4} alt="" />
			<img className="absolute top-[12%] left-[52%] w-[20px] h-[20px]" src={sparkle5} alt="" />
			<img className="absolute top-[21%] left-[88%] w-[35px] h-[35px]" src={sparkle6} alt="" />
		</div>
	);
};
